<template>
  <div
      class="profile-card my-5 test-card"
      v-show="(quiz.time < quiz.end)"
  >
    <div>
      <!--				<div class="text-primary" v-if="testStarted">-->
      <!--					{{-->
      <!--						$t('profile.testing.test-ends', {-->
      <!--							day: new Date(quiz.end * 1000).getDate(),-->
      <!--							month: monthNames[$i18n.locale][new Date(quiz.end * 1000).getMonth()],-->
      <!--							year: new Date(quiz.end * 1000).getFullYear(),-->
      <!--							hours:-->
      <!--                new Date(quiz.end * 1000).getHours() < 10-->
      <!--									? '0' + new Date(quiz.end * 1000).getHours()-->
      <!--									: new Date(quiz.end * 1000).getHours(),-->
      <!--							minutes:-->
      <!--								new Date(quiz.end * 1000).getMinutes() < 10-->
      <!--									? '0' + new Date(quiz.end * 1000).getMinutes()-->
      <!--									: '' + new Date(quiz.end * 1000).getMinutes()-->
      <!--						})-->
      <!--					}}-->
      <!--				</div>-->
      <!--				<div class="text-primary" v-else>-->
      <!--					{{-->
      <!--						$t('profile.testing.test-starts', {-->
      <!--							day: new Date(quiz.start * 1000).getDate(),-->
      <!--							month: monthNames[$i18n.locale][new Date(quiz.start * 1000).getMonth()],-->
      <!--							year: new Date(quiz.start * 1000).getFullYear(),-->
      <!--							hours:-->
      <!--								new Date(quiz.start * 1000).getHours() < 10-->
      <!--									? '0' + new Date(quiz.start * 1000).getHours()-->
      <!--									: '' + new Date(quiz.start * 1000).getHours(),-->
      <!--							minutes:-->
      <!--								new Date(quiz.start * 1000).getMinutes() < 10-->
      <!--									? '0' + new Date(quiz.start * 1000).getMinutes()-->
      <!--									: new Date(quiz.start * 1000).getMinutes()-->
      <!--						})-->
      <!--					}}-->
      <!--				</div>-->
      <h2 class="mb-4">{{ quiz[`title_${$i18n.locale}`] }}</h2>
      <!-- <div class="test-status">
        <svg
          class="mr-2"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 14.5797L7.91108 14.2208C11.3679 11.2023 13.125 8.56672 13.125 6.25C13.125 2.96916 10.5643 0.625 7.5 0.625C4.43566 0.625 1.875 2.96916 1.875 6.25C1.875 8.56672 3.63207 11.2023 7.08892 14.2208L7.5 14.5797ZM7.5 12.9146C4.56663 10.271 3.125 8.03077 3.125 6.25C3.125 3.68538 5.10257 1.875 7.5 1.875C9.89743 1.875 11.875 3.68538 11.875 6.25C11.875 8.03077 10.4334 10.271 7.5 12.9146ZM7.5 3.125C9.22589 3.125 10.625 4.52411 10.625 6.25C10.625 7.97589 9.22589 9.375 7.5 9.375C5.77411 9.375 4.375 7.97589 4.375 6.25C4.375 4.52411 5.77411 3.125 7.5 3.125ZM5.625 6.25C5.625 5.21447 6.46447 4.375 7.5 4.375C8.53553 4.375 9.375 5.21447 9.375 6.25C9.375 7.28553 8.53553 8.125 7.5 8.125C6.46447 8.125 5.625 7.28553 5.625 6.25Z"
            fill="black"
          />
        </svg>
        <span>{{ $t('profile.testing.test-place') }} {{ $t('profile.testing.online-title') }}</span>
      </div> -->
      <div class="test-status">
        <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5 0.625C11.297 0.625 14.375 3.70304 14.375 7.5C14.375 11.297 11.297 14.375 7.5 14.375C3.70304 14.375 0.625 11.297 0.625 7.5C0.625 3.70304 3.70304 0.625 7.5 0.625ZM13.0757 8.24784C12.4131 8.59245 11.5404 8.88061 10.5495 9.07928C10.4087 10.518 10.0755 11.7881 9.59091 12.7236C11.4403 11.9826 12.8047 10.2879 13.0757 8.24784ZM9.36643 8.00277C9.37209 7.83726 9.375 7.66957 9.375 7.5C9.375 4.365 8.379 1.875 7.5 1.875C6.621 1.875 5.625 4.365 5.625 7.5C5.625 7.67932 5.62826 7.85652 5.63457 8.03127C6.22096 8.09258 6.84755 8.12491 7.50031 8.12491C8.14273 8.12491 8.77305 8.07961 9.36643 8.00277ZM10.6226 7.78381C10.6242 7.68965 10.625 7.59504 10.625 7.5C10.625 5.42022 10.2465 3.54206 9.59091 2.27644C11.4511 3.02172 12.8206 4.73185 13.0803 6.78781C12.7859 7.11789 11.8583 7.5099 10.6226 7.78381ZM5.7368 9.29821C6.29759 9.34874 6.88824 9.37491 7.50031 9.37491C8.10228 9.37491 8.69538 9.3408 9.26581 9.27753C8.98389 11.526 8.20438 13.125 7.5 13.125C6.79778 13.125 6.02089 11.5358 5.7368 9.29821ZM4.37847 7.84374C4.01986 7.77219 3.68564 7.68794 3.38047 7.59211C2.60483 7.34853 2.09807 7.04997 1.91608 6.81671C2.16661 4.74789 3.5402 3.02521 5.40909 2.27644C4.75348 3.54206 4.375 5.42022 4.375 7.5C4.375 7.61522 4.37616 7.72982 4.37847 7.84374ZM4.4557 9.13163C4.59945 10.5489 4.9304 11.7995 5.40909 12.7236C3.59165 11.9954 2.24261 10.3462 1.93931 8.35335C2.59146 8.69308 3.45203 8.95714 4.4557 9.13163Z"
              fill="black"
          />
        </svg>
        <span v-if="test_lang === 'ru'"
        >{{ $t('profile.testing.test-language') }}
						{{ $t('profile.testing.russian-lang') }}</span
        >
        <span v-if="test_lang === 'kz'"
        >{{ $t('profile.testing.test-language') }}
						{{ $t('profile.testing.kazakh-lang') }}</span
        >
      </div>
    </div>
    <div>
      <div class="btn-wrap">
        <el-button
            @click="getToken(quiz.id)"
            :disabled="quiz.disabled || (quiz.start > quiz.time) || (quiz.user_tries >= quiz.quiz_tries)"
            :loading="btnLoading"
            class="btn btn-primary"
        >{{ $t('profile.select-testing.btn-go') }}
        </el-button
        >
        <div
            v-if="quiz.start > quiz.time"
            class="hint text-danger"
        >
          {{
            leftTimeToStart | duration('h:mm:ss') | duration('humanize', true)
          }}
        </div>
        <div class="text-primary" v-if="quiz.disabled">
          {{
            $t('profile.testing.test-tries-exhausted')
          }}
        </div>
        <!--<a href="school.html" data-toggle="modal" data-target="#claim-modal" type="button" class="btn btn-outline-primary">Подать заявку</a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quiz: {
      type: Object,
      require
    },
    test_lang: {
      type: String,
      require
    }
  },
  data() {
    return {
      monthNames: {
        ru: [
          'января',
          'февраля',
          'марта',
          'апреля',
          'мая',
          'июня',
          'июля',
          'августа',
          'сентября',
          'октября',
          'ноября',
          'декабря'
        ],
        kz: [
          'қаңтарда',
          'ақпанда',
          'наурызда',
          'сәуiрде',
          'мамырда',
          'маусымда',
          'шiлдеде',
          'тамызда',
          'қыркүйекте',
          'қазанда',
          'қарашада',
          'желтоқсанда'
        ]
      },
      leftTimeToStart: '',
      timerInterval: null,
      btnLoading: false,
      testStarted: false,
      test_lang: '',
    }
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.leftTimeToStart = new Date(this.quiz.start * 1000) - new Date()
      }, 60000)
    },
    getToken(id) {
      this.btnLoading = true
      this.$http.get(API_ROOT + '/api/olympiad/link?quiz_id=' + id)
          .then((res) => {
            this.btnLoading = false
            if (res.body.link) {
              localStorage.setItem('quiz_token', res.body.token)
              window.location = QUIZ_ROOT + '/enter/' + res.body.token;
            }
          }).catch((e) => {
        Vue.toastr({
          message: 'Error',
          description: e.body,
          type: 'error'
        })
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timerInterval)
  },
  beforeCreate() {
    this.test_lang = ''
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.test_lang = res.body.data.education.test_language
        })
    this.leftTimeToStart = new Date(this.quiz.start * 1000) - new Date()
    this.startTimer();
    let currentTimestamp = (+new Date());
    let quizStartTime = (+new Date(this.quiz.start));
    if (currentTimestamp > quizStartTime) {
      this.testStarted = true
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 678px) {
  .profile-card {
    padding: 32px 19px !important;

  }

  .profile-card .text-primary {
    font-size: 12px;
  }

  .profile-card h2 {
    font-size: 22px;
  }

  .test-card {
    flex-wrap: wrap;
    justify-content: center;
  }

  .test-card .btn-wrap {
    margin-top: 15px;
  }
}
</style>
